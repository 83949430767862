import React, { useState } from "react";
import { Headers } from "./layouts/Headers";
import styles from "../styles/Sign.module.css";

interface SignsProps {}

export const Signs: React.FC<SignsProps> = ({}) => {
  const [signDetails, setSignDetails] = useState<SignDetailsType>({
    fullName: "",
    initials: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value, e.target.name);
    const { name, value } = e.target;
    setSignDetails({
      ...signDetails,
      [name]: value,
    });
  };

  return (
    <div>
      <Headers />
      <div className={`py-2 px-4 lg:px-15`}>
        <div className={`p-4 ${styles.signBorder}`}>
          <h4 className="text-black">Create Your Signature </h4>
        </div>
        <div className={`p-4 ${styles.signBorder}`}>
          <div className={`flex items-center justify-start `}>
            <div className="flex items-center justify-center">
              <p className="text-black mr-3">Full Name</p>
              <input
                className={`${styles.input}`}
                name="fullName"
                value={signDetails.fullName}
                onChange={onChange}
              />
            </div>
            <div className="flex items-center justify-center mx-6">
              <p className="text-black mr-3">Initials</p>
              <input
                className={`${styles.input}`}
                name="initials"
                value={signDetails.initials}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type SignDetailsType = {
  fullName: string;
  initials: string;
};
