import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routerConfig from "./config";

interface RoutingProps {}

export const Routing: React.FC<RoutingProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/home" />} />
      {routerConfig.map((Rut: any) => (
        <Route key={Rut.path} path={Rut.path} element={<Rut.Component />} />
      ))}
    </Routes>
  );
};
