import { initialStateType } from "./Signature.context";

export const SignatrueReducer = (state: initialStateType, action: any) => {
  switch (action.type) {
    case "SET_SIGNATURE":
      return {
        ...state,
        loading: true,
      };

    case "GET_SIGNATURE":
      return {
        ...state,
        signImage: action.payload.signImage,
        signKey: action.payload.signId,
        types: action.payload.types,
        initialImage: action.payload.initialsImage,
        initialKey: action.payload.initialsKey,
        signText: action.payload.signText,
        initialText: action.payload.initialText,
        fontId: action.payload.fontId,
        signId: action.payload.signId,
        loading: false,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};
