import React from "react";
import { MdOutlineCloudUpload } from "react-icons/md";
import { RiUserAddLine } from "react-icons/ri";
import { GrSend } from "react-icons/gr";
import { FaFileSignature } from "react-icons/fa";
import { FcFile } from "react-icons/fc";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Link } from "react-router-dom";

interface UploadSectionMainProps {}

export const UploadSectionMain: React.FC<UploadSectionMainProps> = ({}) => {
  return (
    <div className="w-full border-secondary border-2 border-dashed p-8 rounded-5 border-opacity-70">
      <div className="grid grid-flow-row-dense grid-cols-4 grid-rows-1 auto-cols-max mt-4 px-15 w-full">
        <div className="flex items-center justify-center flex-col opacity-60 main-step">
          <MdOutlineCloudUpload color="black" size={25} />
          <p className="text-black w-1/2 text-center">Upload documents</p>
        </div>
        <div className="flex items-center justify-center flex-col opacity-60 main-step">
          <RiUserAddLine color="black" size={25} />
          <p className="text-black w-1/2 text-center">Add recipients</p>
        </div>
        <div className="flex items-center justify-center flex-col opacity-60 main-step">
          <GrSend color="black" size={25} />
          <p className="text-black w-1/2 text-center">Send to recipients</p>
        </div>
        <div className="flex items-center justify-center flex-col opacity-60">
          <FaFileSignature color="black" size={25} />
          <p className="text-black w-1/2 text-center">Get signed documents</p>
        </div>
      </div>
      <div className="flex items-center justify-center mt-4">
        <p className="text-black opacity-70 font-semibold">
          Drag and Drop Files or{" "}
          <Link to="/manage">
            <span className="text-red">Browser Documents</span>
          </Link>
        </p>
      </div>
      <div className="flex items-center justify-center mt-4 flex-col">
        <FcFile size={75} />
        <Link to="/manage">
          <button className="actionBtn">
            Upload <AiOutlineCloudUpload size={20} className="ml-2" />
          </button>
        </Link>
      </div>
    </div>
  );
};
