import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Routing } from "./router/route";
import { SnackbarProvider } from "notistack";
import { Collapse } from "@mui/material";
import { SignatureProvider } from "./context/signature/Signature.context";
import { ManageProvider } from "./context/signature/manage/manage.context";

function App() {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      TransitionComponent={Collapse}
    >
      <SignatureProvider>
        <ManageProvider>
          <BrowserRouter>
            <Routing />
          </BrowserRouter>
        </ManageProvider>
      </SignatureProvider>
    </SnackbarProvider>
  );
}

export default App;
