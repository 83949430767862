import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { GrDrag } from "react-icons/gr";

interface ManageProps {}

export const SelectRecipients: React.FC<ManageProps> = () => {
  const [signOrder, setSignOrder] = React.useState<boolean>(false);
  return (
    <div className="p-4 w-2/3 mx-auto">
      <div className="w-full border-secondary bg-primary-1 p-4 rounded-5 border-opacity-70 relative pb-6 drop-shadow-md">
        <div className="absolute top-0 left-0 px-2 flex items-center justify-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={signOrder}
                  onChange={(e) => setSignOrder(e.target.checked)}
                />
              }
              label="Set signing order"
              className="text-black"
            />
          </FormGroup>
          <p className="text-black mx-[2px] text-lg">|</p>
          <p className="text-black text-base">View</p>
        </div>

        <div className="flex items-start flex-col justify-start  mt-6">
          <div className="flex items-center justify-start w-3/4">
            <GrDrag className="cursor-grabbing" />
            <p className="text-black text-lg">1</p>
            <div className="flex flex-col items-start ml-4 relative">
              <p className="text-black absolute top-[-25px]">
                Name<span className="text-red">*</span>
              </p>
              <input
                className="w-full px-[10px] py-[2px] border-2 rounded-md border-black text-black focus:outline-none"
                placeholder="Name"
                type="text"
              />
            </div>
            <div className="flex flex-col items-start ml-4 relative">
              <p className="text-black absolute top-[-25px]">
                Email<span className="text-red">*</span>
              </p>
              <input
                className="w-full px-[10px] py-[2px] border-2 rounded-md border-black text-black focus:outline-none"
                placeholder="Email"
                type="email"
              />
            </div>
            <div className="border border-opacity-50 border-black rounded-lg hover:bg-primary-100 cursor-pointer ml-4">
              <p className="px-[10px] py-[2px] text-black">Add</p>
            </div>
          </div>
          <div className="flex items-center justify-start w-3/4 mt-6">
            <GrDrag className="cursor-grabbing" />
            <p className="text-black text-lg">2</p>
            <div className="flex flex-col items-start ml-4 relative">
              <p className="text-black absolute top-[-20px]">
                Name<span className="text-red">*</span>
              </p>
              <input
                className="w-full px-[10px] py-[2px] border-2 rounded-md border-black text-black focus:outline-none"
                placeholder="Name"
                type="text"
              />
            </div>
            <div className="flex flex-col items-start ml-4 relative">
              <p className="text-black absolute top-[-20px]">
                Email<span className="text-red">*</span>
              </p>
              <input
                className="w-full px-[10px] py-[2px] border-2 rounded-md border-black text-black focus:outline-none"
                placeholder="Email"
                type="email"
              />
            </div>
            <div className="border border-opacity-50 border-black rounded-lg hover:bg-primary-100 cursor-pointer ml-4">
              <p className="px-[10px] py-[2px] text-black">Add</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
