import { useSnackbar } from "notistack";
import React, { createContext, useEffect, useReducer } from "react";
import { SignatrueReducer } from "./Signature.reducer";

export interface initialStateType {
  signImage: string;
  signKey: string;
  initialImage: string;
  initialKey: string;
  types: string;
  isDefault: boolean;
  isDeleted: boolean;
  userId: any;
  companyId: any;
  loading: boolean;
  signText: string;
  initialText: string;
  fontId: string;
}

export const initialState: initialStateType = {
  signImage: "",
  signKey: "",
  initialImage: "",
  initialKey: "",
  fontId: "",
  types: "",
  isDefault: true,
  isDeleted: false,
  userId: 1,
  companyId: 1,
  loading: true,
  signText: "",
  initialText: "",
};

const SignatureContext = createContext(initialState);

export default SignatureContext;

interface SignatureProviderProps {
  children: React.ReactNode;
}

const baseURL = process.env.REACT_APP_API_URL;
const headerKey = process.env.REACT_APP_API_HEADER_KEY;

export const SignatureProvider: React.FC<SignatureProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(SignatrueReducer, initialState);
  const { enqueueSnackbar } = useSnackbar();

  const setSignImage = async (data: any) => {
    // console.log({ data });
    dispatch({
      type: "SET_SIGNATURE",
    });
    await fetch(`${baseURL}/sign/add-sign/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        key: `${headerKey}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.text())
      .then(async (res) => {
        console.log({ res });

        enqueueSnackbar("Signature Added Successfully", {
          variant: "success",
        });
        await getSignImage();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const getSignImage = async () => {
    await fetch(`${baseURL}/sign/get-sign/1/1`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        key: `${headerKey}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log({ res: res[res.length - 1] });
        if (!res.err) {
          dispatch({
            type: "GET_SIGNATURE",
            payload: res[res.length - 1],
          });
          // enqueueSnackbar("Something went wrong", { variant: "error" });
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  useEffect(() => {
    getSignImage();
  }, []);

  return (
    <SignatureContext.Provider
      value={{ ...state, setSignImage, getSignImage } as any}
    >
      {children}
    </SignatureContext.Provider>
  );
};
