import { useSnackbar } from "notistack";
import React, { createContext, useReducer } from "react";
import { Managereducer } from "../manage/manage.reducer";

export const initialState: any = {
  documentDetails: [],
  allpendingdocument: [],
  loading: false,
};

const ManageContext = createContext(initialState);

export default ManageContext;

interface ManageProviderProps {
  children: React.ReactNode;
}

const baseURL = process.env.REACT_APP_API_URL;
const headerKey = process.env.REACT_APP_API_HEADER_KEY;

export const ManageProvider: React.FC<ManageProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(Managereducer, initialState);
  const { enqueueSnackbar } = useSnackbar();

  const getAllPendingDocuments = async (userId: string, companyId: string) => {
    await fetch(
      `${baseURL}/upload/get-pending-documents-header?userId=${userId}&companyId=${companyId}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          key: `${headerKey}`,
        },
      }
    )
      .then((res) => res.json())
      .then(async (res) => {
        // let docHeaderIds = res.map((doc: any) => doc.documentHeaderId);
        // console.log({ docHeaderIds });
        if (!res.err) {
          dispatch({
            type: "GET_ALLPENDINGDOCUMENT",
            payload: res,
          });
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  const getDocumentDetails = async (
    userId: string,
    companyId: string,
    documentHeaderId: string
  ) => {
    // console.log("userId", userId);
    await fetch(
      `${baseURL}/upload/get-document-detail?userId=${userId}&companyId=${companyId}&documentHeaderId=${documentHeaderId}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          key: `${headerKey}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log({ res });
        if (!res.err) {
          dispatch({
            type: "GET_DOCUMENTDETAILS",
            payload: res,
          });
          // enqueueSnackbar("Something went wrong", { variant: "error" });
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log({ err });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  return (
    <ManageContext.Provider
      value={
        {
          ...state,
          getDocumentDetails,
          getAllPendingDocuments,
        } as any
      }
    >
      {children}
    </ManageContext.Provider>
  );
};
