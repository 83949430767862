import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { BiMailSend } from "react-icons/bi";
import styles from "../../styles/Manage.module.css";

interface ReviewDetailsProps {}

export const ReviewDetails: React.FC<ReviewDetailsProps> = ({}) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  return (
    <div className="p-4 w-1/2 mx-auto ">
      <div className="w-full border-secondary bg-primary-1 p-4 rounded-5 border-opacity-70 relative pb-6 drop-shadow-md">
        <div className="flex items-start flex-col justify-start p-2">
          <div className="flex items-center justify-start w-full opacity-80">
            <div className="flex flex-col items-start relative w-full ">
              <p className="text-black absolute top-[-25px]">Document Name</p>
              <input
                className="w-full px-[10px] py-[2px] border-2 rounded-md border-black text-black focus:outline-none"
                placeholder="abc.pdf"
                type="text"
              />
            </div>
          </div>
          <div className="flex items-center justify-start w-full mt-6 opacity-80">
            <div className="flex flex-col items-start relative w-full">
              <p className="text-black absolute top-[-25px]">Email Subject</p>
              <input
                className="w-full px-[10px] py-[2px] border-2 rounded-md border-black text-black focus:outline-none"
                placeholder="Please sign this document - abc.pdf"
                type="text"
              />
            </div>
          </div>
          <div className="flex items-center justify-start  w-full mt-6 opacity-80">
            <div className="flex flex-col items-start relative w-full">
              <p className="text-black absolute top-[-25px]">
                Message (Optional)
              </p>
              <textarea
                className="w-full h-10 max-h-10  px-[10px] py-[2px] border-2 rounded-md border-black text-black focus:outline-none"
                placeholder="Message..."
              />
            </div>
          </div>
          <div className="flex items-center justify-start  w-full mt-8 opacity-80">
            <div className="flex flex-col items-start relative w-full">
              <p className="text-black absolute top-[-25px]">Documents</p>
              <div className={`${styles.content} ${styles.upload}`}>
                <div className={`${styles.details}`}>
                  <div className="flex flex-col">
                    <span
                      className="name text-black font-semibold"
                      style={{ fontSize: "1em" }}
                    >
                      {"abc.pdf "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start  w-full mt-6 opacity-80">
            <div className="flex flex-col items-start relative w-full">
              <p className="text-black absolute top-[-25px]">Recipients</p>
              <div className="flex items-center justify-between w-3/4 mt-2 border border-black rounded-md p-2">
                <p className="text-black text-lg pr-4 border-r">1</p>
                <div className="flex flex-col items-start relative">
                  <input
                    className="w-full px-[10px] py-[2px]  rounded-md text-black focus:outline-none"
                    placeholder="Name"
                    type="text"
                    disabled
                    value={"John Doe"}
                  />
                </div>
                <div className="flex items-center justify-center ml-4 relative">
                  <BiMailSend size={35} className="text-black mr-1" />
                  <input
                    className="w-full px-[10px] py-[2px] rounded-md  text-black focus:outline-none"
                    placeholder="Email"
                    type="email"
                    disabled
                    value={"johnDoe@gmail.com"}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between w-3/4 mt-2 border border-black rounded-md p-2">
                <p className="text-black text-lg pr-4 border-r">1</p>
                <div className="flex flex-col items-start relative">
                  <input
                    className="w-full px-[10px] py-[2px]  rounded-md text-black focus:outline-none"
                    placeholder="Name"
                    type="text"
                    disabled
                    value={"John Doe"}
                  />
                </div>
                <div className="flex items-center justify-center ml-4 relative">
                  <BiMailSend size={35} className="text-black mr-1" />
                  <input
                    className="w-full px-[10px] py-[2px] rounded-md  text-black focus:outline-none"
                    placeholder="Email"
                    type="email"
                    disabled
                    value={"johnDoe@gmail.com"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full border-secondary bg-primary-1 p-4 rounded-5 border-opacity-70 relative pb-6 drop-shadow-md mt-4">
        <div className="flex items-center justify-start  w-full mt-6 opacity-80">
          <div className="flex flex-col items-start relative w-full">
            <p className="text-black absolute top-[-25px]">
              Document Preferences
            </p>

            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Notify when document is viewed"
                  className="text-black"
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Notify when document is signed by each signer"
                  className="text-black"
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Notify on document completed"
                  className="text-black"
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Enable Auto reminders"
                  className="text-black"
                />
              </FormGroup>
            </div>

            <div className="flex flex-col items-start relative w-1/4 mt-6">
              <p className="text-black absolute top-[-30px]">
                Expire Document After
              </p>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Duration
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Select Duration"
                  onChange={handleChange}
                >
                  <MenuItem value={"1 Month"}>1 Month</MenuItem>
                  <MenuItem value={"2 Months"}>2 Months</MenuItem>
                  <MenuItem value={"3 Months"}>3 Months</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
