import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SignatureContext from "../context/signature/Signature.context";
import { Headers } from "./layouts/Headers";
import { UploadSectionMain } from "./Main/UploadSection.Main";
import { SignaturePopup } from "./signature/SignaturePopup";
import { dataFont } from "./signature/Tabs/SignatureSelect";

interface MainProps {}

export const Main: React.FC<MainProps> = ({}) => {
  const [modal, setModal] = useState<boolean>(false);
  const { signImage, signText, fontId, loading } = useContext(SignatureContext);

  const setShowModal = (value: boolean) => {
    setModal(value);
  };

  return (
    <>
      <Headers />
      <div className="py-2 px-4 lg:px-15">
        <div>
          <h2 className="text-black opacity-80">E-Sign Documents Online</h2>
        </div>
        <div className="grid grid-flow-row-dense grid-cols-6 grid-rows-1 auto-cols-max mt-4 home-cards">
          <div className="max-w-sm bg-primary-1 rounded-lg  border border-primary-200 shadow-outlineSm mr-2 my-2 w-11 h-10">
            <img src="https://google.github.io/cartoonset/images/cs100367196602765414.png" />
          </div>
          <div className="max-w-sm bg-primary-1 rounded-lg  border border-primary-200 shadow-outlineSm my-2 overflow-hidden relative">
            {loading ? (
              <div className="flex items-center justify-center px-2 py-1.5">
                <CircularProgress />
              </div>
            ) : (
              <div className="flex items-start justify-between flex-col px-2 py-1.5">
                <p className="font-normal text-xs text-black hover:text-primary-800 text-center ">
                  My Signature
                </p>
                {signImage ? (
                  <img src={signImage} className="h-8 w-full" />
                ) : (
                  <p
                    className={`w-auto text-2xl text-black ${
                      dataFont.find((item) => item.id === fontId)?.fontClass
                    } `}
                  >
                    {signText}
                  </p>
                )}
              </div>
            )}
            {/* <div className="flex items-center justify-between px-2 py-1.5">
              <p className="font-normal text-xs text-black hover:text-primary-800 text-center">
                My Initials
              </p>
              <p className="font-normal text-sm text-black hover:text-primary-800 text-center">
                Aditya
              </p>
            </div> */}
            {!loading && (
              <div
                className="cursor-pointer absolute bottom-1 right-2"
                onClick={() => setModal(true)}
              >
                <div className="text-accent text-sm">Edit</div>
              </div>
            )}
          </div>
          <div className="max-w-sm bg-primary-1 rounded-lg  border border-primary-200 w-5/6 shadow-outlineSm my-2 mx-4 flex items-center justify-center flex-col">
            <p className="font-normal px-1.5 text-black hover:text-primary-800 text-center text-base">
              Actions Required
            </p>
            <span className="text-black text-xl">00</span>
          </div>
          <div className="max-w-sm bg-primary-1 rounded-lg  border border-primary-200 shadow-outlineSm my-2 mx-4 flex items-center justify-center flex-col">
            <p className="font-normal p-1 text-black hover:text-primary-800 text-center">
              Waiting
            </p>
            <span className="text-black text-xl">00</span>
          </div>
          <div className="max-w-sm bg-primary-1 rounded-lg  border border-primary-200 shadow-outlineSm my-2 mx-4 flex items-center justify-center flex-col">
            <p className="font-normal p-1 text-black hover:text-primary-800 text-center">
              Expiring Soon
            </p>
            <span className="text-black text-xl">00</span>
          </div>
          <div className="max-w-sm bg-primary-1 rounded-lg  border border-primary-200 shadow-outlineSm my-2 mx-4 flex items-center justify-center flex-col">
            <p className="font-normal p-1 text-black hover:text-primary-800 text-center">
              Complete
            </p>
            <span className="text-black text-xl">00</span>
          </div>
        </div>

        <div className="mt-4">
          <h3 className="text-black opacity-80">Get Started</h3>
        </div>
        <UploadSectionMain />
        {modal && <SignaturePopup setShowModal={setShowModal} modal={modal} />}
      </div>
    </>
  );
};
