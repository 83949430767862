import React, { useEffect } from "react";
import { NavLink as Link, useLocation } from "react-router-dom";

interface HeadersProps {}

export const Headers: React.FC<HeadersProps> = ({}) => {
  const { pathname } = useLocation();
  useEffect(() => {}, [pathname]);

  return (
    <nav className="flex items-center justify-between flex-wrap bg-primary-1 p-2 px-4 lg:px-15">
      <Link to="/">
        <div className="flex items-center flex-no-shrink text-black mr-6">
          <span className="font-semibold text-2xl tracking-tight">E-Sign</span>
        </div>
      </Link>
      <div className="block lg:hidden">
        <button className="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white">
          <svg
            className="h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        {pathname !== "/login" ? (
          <div className="text-sm lg:flex-grow">
            <Link to="/home" className="mainTitle">
              <span className="block mt-4 lg:inline-block lg:mt-0 text-black hover:text-white mr-4 text-lg ">
                Home
              </span>
            </Link>
            <Link to="/manage" className="mainTitle">
              <span className="block mt-4 lg:inline-block lg:mt-0 text-black hover:text-white mr-4 text-lg ">
                Manage
              </span>
            </Link>
            {/* <Link to="/sign" className="mainTitle">
              <a className="block mt-4 lg:inline-block lg:mt-0 text-black hover:text-white mr-4 text-lg ">
                Signatures
              </a>
            </Link> */}
          </div>
        ) : null}
        {pathname !== "/login" ? (
          <Link to="/login">
            <div className="">
              <span className="text-black pr-4">Hello, Kate Horwitz</span>
              <img
                className="inline-block h-7 w-7 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
            </div>
          </Link>
        ) : null}
      </div>
    </nav>
  );
};
