import React, { useEffect, useState } from "react";
import styles from "../styles/Login.module.css";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../helpers/Helpers";
import { CircularProgress } from "@mui/material";
import { Headers } from "./layouts/Headers";

interface LoginProps {}

export const Login: React.FC<LoginProps> = ({}) => {
  const [signIn, setSignIn] = useState<boolean>(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [login, setLogin] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    navigate("/");
    if (login.email === "" || login.password === "") {
    }
    if (login.password.length < 6) {
      return;
    }

    if (validateEmail(login.email) === null) {
      return;
    }

    // all ok
    setLoading(true);
  };

  const toggleSignIn = () => {
    setSignIn(!signIn);
  };

  return (
    <>
      <Headers />
      <div className={styles.loginContainer}>
        <div className={styles.title}>
          <p>Login</p>
        </div>
        <div className={styles.left}>
          <div className={styles.card}>
            <div className={styles.loginTitle}>
              <p className={styles.upperInfo}>Sign In to your account</p>
            </div>
            <div className={styles.loginForm}>
              <div className={styles.feild}>
                <div className={styles.label}>
                  <p>Email</p>
                </div>
                <input
                  className={styles.inputFeild}
                  type="email"
                  name="email"
                  placeholder="Type in your email.."
                  required
                  onChange={onChange}
                />
              </div>
              <div className={styles.feild}>
                <div className={styles.label}>
                  <p>password</p>
                </div>
                <input
                  className={styles.inputFeild}
                  type="password"
                  name="password"
                  placeholder="password"
                  required
                  onChange={onChange}
                />
              </div>
              <button
                className={styles.btn}
                onClick={() => handleLogin()}
                disabled={loading}
              >
                Login
                {loading && (
                  <CircularProgress
                    size={20}
                    color={"inherit"}
                    className={styles.loader}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
        <div className={styles.border} />
        <div className={styles.right}>
          <div className={styles.lottie}>
            <Lottie
              style={{
                borderRadius: "10%",
              }}
              options={{
                loop: true,
                autoplay: true,
                animationData: require(`../assets/Login.json`),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
