import { Divider } from "@mui/material";
import React, { useState } from "react";
import { BiArrowBack, BiRightArrowAlt } from "react-icons/bi";
import { signActions } from "../../helpers/Constants";
import Documentdetailsidebar from "../layouts/Documentdetailsidebar";
import { Headers } from "../layouts/Headers";
import { AddDocuments } from "./AddDocuments";
import { ReviewDetails } from "./ReviewDetails";
import { SelectRecipients } from "./SelectRecipients";

interface DocumentDetailsProps {
  children?: React.ReactNode;
}

export const DocumentDetails: React.FC<DocumentDetailsProps> = ({
  children,
}) => {
  const [type, setType] = useState<number>(0);

  return (
    <>
      <Headers />
      <Documentdetailsidebar type={type} setType={setType}>
        <div className="flex flex-col w-full px-4 overflow-y-auto overflow-x-hidden pb-400">
          <div className="h-3 py-4 m-3 flex items-center justify-between w-full ">
            <div className="flex items-center justify-between">
              {signActions[type] !== "Add Documents" && (
                <BiArrowBack
                  onClick={() => setType(type - 1)}
                  size={20}
                  className="text-black mr-6 cursor-pointer"
                  title="previous"
                />
              )}
              <h3 className="text-black opacity-75 font-normal">
                {signActions[type]}
              </h3>
            </div>

            <div className="flex items-center justify-between px-4">
              <div className="border border-opacity-50 border-black rounded-lg hover:bg-primary-100 cursor-pointer mr-3">
                <p className="px-[10px] py-[3px] text-black">Save & Close</p>
              </div>
              {signActions[type] !== "Review" && (
                <div
                  className="rounded-lg bg-red cursor-pointer px-[10px] py-[3px]"
                  onClick={() => setType(type + 1)}
                >
                  <div className="flex items-center justify-between ">
                    <p className=" font-semibold">Next</p>
                    <BiRightArrowAlt
                      size={20}
                      className="text-white ml-1 cursor-pointer"
                      title="previous"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <Divider />
          </div>
          {signActions[type] === "Add Documents" && <AddDocuments />}
          {signActions[type] === "Add Recipients" && <SelectRecipients />}
          {signActions[type] === "Review" && <ReviewDetails />}
        </div>
      </Documentdetailsidebar>
    </>
  );
};
