import moment from "moment";
import React from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/Manage.module.css";

interface AllDocumentsTableProps {
  allDocs: any[];
}

export const AllDocumentsTable: React.FC<AllDocumentsTableProps> = ({
  allDocs,
}) => {
  const navigate = useNavigate();

  const handlesign = () => {
    navigate("/documentDetails");
  };
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden ">
            <div>
              <table className="min-w-full divide-y divide-black border-opacity-60 table-fixed">
                <thead className="bg-gray-100 ">
                  <tr>
                    <th scope="col" className="p-4">
                      <div className="hidden">
                        <input
                          id="checkbox-all"
                          type="checkbox"
                          className="w-2 h-2 text-blue-600 bg-gray-100 rounded border-gray-300 "
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-lg font-bold tracking-wider text-left text-black uppercase "
                    >
                      Subject
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-lg font-bold tracking-wider text-left text-black uppercase "
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-lg font-bold tracking-wider text-left text-black uppercase "
                    >
                      Last Changed
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allDocs.length > 0
                    ? allDocs.map((doc: any, i: any) => (
                        <tr
                          key={doc?.documentHeaderId}
                          className="hover:bg-gray-100 dark:hover:bg-gray-700"
                        >
                          <td className="p-2 w-2">
                            <div className="flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                className="w-3 h-3 text-blue-600 bg-gray-100 rounded border-gray-300"
                              />
                            </div>
                          </td>
                          <td className="py-3 px-6 text-md font-bold tracking-wider text-left text-black uppercase ">
                            {doc?.envelopeName}
                          </td>
                          <td className="py-3 px-6 text-sm font-semibold opacity-80 tracking-wider text-left text-black uppercase ">
                            {doc?.signerStatus}
                          </td>
                          <td className="py-3 px-6 text-sm font-semibold tracking-wider opacity-80 text-left text-black uppercase ">
                            {moment(doc?.lastUpdated).format(
                              "MM/D/YYYY, h:mm:ss a"
                            )}
                          </td>

                          <td className="py-3 px-6 text-xs font-medium tracking-wider text-left text-black uppercase ">
                            <button
                              className={styles.btn}
                              onClick={() => handlesign()}
                            >
                              Sign
                              <AiOutlineCaretDown size={20} />
                            </button>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
