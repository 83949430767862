import { DocumentDetails } from "../components/Documentdetails/DocumentDetails";
import { Login } from "../components/Login";
import { Main } from "../components/Main";
import { Manage } from "../components/Manage";
import { Signs } from "../components/Signs";

export default [
  {
    path: "/home",
    Component: Main,
  },
  {
    path: "/manage",
    Component: Manage,
  },
  {
    path: "/sign",
    Component: Signs,
  },

  {
    path: "/documentDetails",
    Component: DocumentDetails,
  },

  {
    path: "/login",
    Component: Login,
  },
];
