import { Divider } from "@mui/material";
import React, { useRef, useState } from "react";
import styles from "../../../styles/Sign.module.css";
import CanvasDraw from "react-canvas-draw";
import { GrClear } from "react-icons/gr";

export interface SignatureDrawProps {
  setSignInitialsDetails: React.Dispatch<any>;
  signInitialsDetails: any;
}

export const SignatureDraw: React.FC<SignatureDrawProps> = ({
  setSignInitialsDetails,
  signInitialsDetails,
}) => {
  const canvasSignatureRef = useRef<any>(null);
  const canvasInitialsRef = useRef<any>(null);

  const handleExport = (canvas: any, name: string) => {
    setSignInitialsDetails({
      ...signInitialsDetails,
      [name]: canvas.canvasContainer.childNodes[1].toDataURL(),
    });
  };

  return (
    <div className={`w-full ${styles.signDraw}`}>
      <div className="flex items-center justify-center w-full ">
        <div className="w-1/2 h-4/5 mx-2 flex items-center justify-center flex-col relative">
          <CanvasDraw
            lazyRadius={0}
            brushRadius={2}
            ref={canvasSignatureRef}
            className={`${styles.canvasBorder}`}
            onChange={(canvas: any) => handleExport(canvas, "signImage")}
          />

          <div className="absolute bottom-6 left-4 z-50 opacity-80 p-2 cursor-pointer">
            <GrClear
              size={25}
              color={"black"}
              onClick={() => canvasSignatureRef.current.clear()}
            />
          </div>
        </div>
        <Divider orientation="vertical" flexItem className="pt-1" />
        <div className="w-1/2 mx-2 flex items-center justify-center flex-col relative">
          <CanvasDraw
            lazyRadius={0}
            brushRadius={2}
            ref={canvasInitialsRef}
            className={`${styles.canvasBorder}`}
            onChange={(canvas: any) => handleExport(canvas, "initialsImage")}
          />

          <div className="absolute bottom-6 left-4 z-50 opacity-80 p-2 cursor-pointer">
            <GrClear
              size={25}
              color={"black"}
              onClick={() => canvasInitialsRef.current.clear()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
