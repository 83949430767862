import { Divider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MdClose, MdSearch } from "react-icons/md";
import { Search, StyledInputBase } from "../helpers/SearchBox";
import { Headers } from "./layouts/Headers";
import SideBar from "./layouts/ManageSideBar";
import ManageContext from "../context/signature/manage/manage.context";
import { AllDocumentsTable } from "./Manage/AllDocumentsTable";

interface ManageProps {}

export const Manage: React.FC<ManageProps> = ({}) => {
  const [searched, setsearched] = useState<string>("");
  const [showesearchclose, setshowesearchclose] = useState<boolean>(false);
  const [type, setType] = useState<string>("All Documents");
  const { allpendingdocument, getAllPendingDocuments } =
    useContext<any>(ManageContext);
  const [allDocs, setAllDocs] = useState<any[]>(allpendingdocument);

  const requestSearch = (searchedVal: any) => {
    if (searchedVal?.target?.value === "") {
      setsearched("");
      setshowesearchclose(false);
      setAllDocs(allpendingdocument);
    } else {
      setshowesearchclose(true);
      setsearched(searchedVal.target.value);
      let filteredRows = allDocs.filter(
        (data: any) =>
          JSON.stringify(data)
            .toLowerCase()
            .indexOf(searchedVal.target.value.toString().toLowerCase()) !== -1
      );
      setAllDocs(filteredRows);
    }
  };

  useEffect(() => {
    getAllPendingDocuments("1", "1");
  }, []);

  useEffect(() => {
    setAllDocs(allpendingdocument);
  }, [allpendingdocument]);

  const cancelSearch = () => {
    setsearched("");
    setshowesearchclose(false);
    setAllDocs(allpendingdocument);
  };

  return (
    <>
      <Headers />
      <SideBar type={type} setType={setType}>
        <div className="flex flex-col w-full px-4">
          <div className="h-4 p-4 m-4 flex items-center justify-between w-full ">
            <div>
              <h3 className="text-black">{type}</h3>
            </div>
            <div className="flex w-3/4 items-center justify-end">
              <Search>
                {<MdSearch className="text-black text-2xl opacity-50" />}
                <StyledInputBase
                  placeholder="Search Document"
                  inputProps={{ "aria-label": "search" }}
                  value={searched}
                  onChange={(e) => requestSearch(e)}
                  className="text-black"
                />
                {showesearchclose && (
                  <MdClose
                    onClick={() => cancelSearch()}
                    className="text-black text-2xl cursor-pointer"
                  />
                )}
              </Search>
            </div>
          </div>
          <div>
            <Divider />
          </div>
          <AllDocumentsTable allDocs={allDocs} />
        </div>
      </SideBar>
    </>
  );
};
