import React from "react";

interface SideBarProps {
  children?: React.ReactNode;
  type: string;
  setType: any;
}

const ManageSideBar: React.FC<SideBarProps> = ({ type, setType, children }) => {
  return (
    <div className="flex flex-no-wrap h-full">
      <div className="w-1/6 sm:relative bg-primary-900 shadow md:h-full flex-col hidden sm:flex py-4 h-full pl-4">
        <div className="mb-4">
          <h4 className="opacity-90">Documents</h4>
        </div>

        <p
          className={`text-lg ml-2 opacity-80 cursor-pointer ${
            type === "All Documents" && "text-red"
          }`}
          onClick={() => setType("All Documents")}
        >
          All Documents
        </p>
        <div className="pl-4">
          <ol className="relative dark:border-gray-700">
            <li
              className="mb-1 ml-2 cursor-pointer opacity-80 hover:opacity-100"
              onClick={() => setType("Action")}
            >
              <span
                className={`text-lg ml-2 ${type === "Action" && "text-red"}`}
              >
                Action
              </span>
            </li>
            <li
              className="mb-1 ml-2 cursor-pointer opacity-80 hover:opacity-100"
              onClick={() => setType("Waiting")}
            >
              <span
                className={`text-lg ml-2 ${type === "Waiting" && "text-red"}`}
              >
                Waiting{" "}
              </span>
            </li>
            <li
              className="mb-1 ml-2 cursor-pointer opacity-80 hover:opacity-100"
              onClick={() => setType("Expiring Soon")}
            >
              <span
                className={`text-lg ml-2 ${
                  type === "Expiring Soon" && "text-red"
                }`}
              >
                Expiring Soon
              </span>
            </li>
            <li
              className="mb-1 ml-2 cursor-pointer opacity-80 hover:opacity-100"
              onClick={() => setType("Complete")}
            >
              <span
                className={`text-lg ml-2 ${type === "Complete" && "text-red"}`}
              >
                Complete
              </span>
            </li>
          </ol>
        </div>
      </div>
      {children}
    </div>
  );
};

export default ManageSideBar;
