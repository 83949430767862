import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import React, { useState } from "react";
import FileUpload, { fileTypes } from "../fileuploader/FileUpload";

interface AddFilesProps {}

export const AddDocuments: React.FC<AddFilesProps> = () => {
  const [files, setFiles] = useState<any[]>([]);
  const [onlySigner, setOnlySigner] = useState<boolean>(false);

  const handleFileUpload = (
    event: { currentTarget: { files: any } },
    type?: string
  ) => {
    if (event.currentTarget) {
      // console.log([...event.currentTarget.files]);
      let fileArray: any[] = [];
      [...event.currentTarget.files].forEach((file) => {
        if (fileTypes.includes(file.name.split(".").pop())) {
          fileArray.push(file);
        } else {
        }
      });
      setFiles((prevState) => [...prevState, ...fileArray]);
    }
  };
  return (
    <div className="p-4 w-2/3 mx-auto">
      <div className="w-full border-secondary bg-primary-1 p-4 rounded-5 border-opacity-70 relative pb-6 drop-shadow-md">
        <FileUpload
          multiple={true}
          DisplayText={fileText}
          format={true}
          files={files}
          handleFileUpload={handleFileUpload}
          setFiles={setFiles}
        />
        <div className="absolute bottom-0 right-0">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlySigner}
                  onChange={(e) => setOnlySigner(e.target.checked)}
                />
              }
              label="I am the only Signer"
              className="text-black"
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};
const fileText = (
  <p className="text-black opacity-70 font-semibold text-3xl">
    Drag and Drop Files or <span className="text-red"> Browser Documents</span>
  </p>
);
